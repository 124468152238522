import { useEffect, useState } from 'react';
import indexStore from '../mobx/store';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getAPI, postAPI, postFormAPI } from '../utils/api';
import { S3TokenData, SupportedChainAndNetwork } from '../types/searchToken';
import { useObserver } from 'mobx-react';
import { dataURLtoFile } from '../utils/util';
import React from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import './SearchTokenPage.scss';

export default function SearchTokenPage() {
  const { popupStore } = indexStore();
  const isLoading = popupStore.isLoading;
  const loadingImg = require('../images/loading.gif');

  // 상태 관리
  const [chain, setChain] = useState<string>('');
  const [network, setNetwork] = useState<string>('');
  const [searchAddress, setSearchAddress] = useState<String | any>('');
  const [metadata, setMetadata] = useState<string>('{}');

  const [icon, setIcon] = useState<any>(null);
  const [mimeType, setMimeType] = useState<string>('');
  const [iconSize, setIconSize] = useState<number>(0);
  const [noImg] = useState(require('../images/noImg.jpg'));
  const [iconInfo, setIconInfo] = useState<any>([]);
  const [iconPreview, setIconPreview] = useState<any>(null);

  // 서버에서 받아올 지원하는 chain, network 목록
  const [chainList, setChainList] = useState<string[]>([]);
  const [networkList, setNetworkList] = useState<string[]>([]);

  const [isResult, setIsResult] = useState<boolean>(false);

  const [fileInput] = useState<any>(React.createRef());

  const navigator = useNavigate();
  const location = useLocation();

  async function getChainAndNetwork() {
    const response = await (await getAPI('chains-and-networks')).json();
    console.warn(response);
    if (response.statusCode === 401) {
      alertPopup('재로그인 필요');
      navigator('/');
      return;
    }
    setChainAndNetwork(response.result);
  }

  function setChainAndNetwork(result: SupportedChainAndNetwork) {
    setChainList(result.chains);
    setNetworkList(result.networks);
    setChain(result.chains[0]); // 기본값 세팅
    setNetwork(result.networks[0]); // 기본값 세팅
  }

  useEffect(() => {
    if (chainList.length === 0 && networkList.length === 0) {
      getChainAndNetwork();
    }
  });

  function inputHandler(keyword: String) {
    setSearchAddress(keyword);
  }

  function jsonFormatHandler() {
    try {
      setMetadata(JSON.stringify(JSON.parse(metadata), null, '\t'));
    } catch (e) {
      alertPopup('JSON format 에 맞지 않습니다.');
    }
  }

  function textAreaHandler(keyword: string) {
    setMetadata(keyword);
  }

  function searchHandler() {
    getSearchResult();
  }

  function changeChainHandler(e: any) {
    setChain(e.target.value);
  }

  function changeNetworkHandler(e: any) {
    setNetwork(e.target.value);
  }

  function handleKeyPress(e: any) {
    if (e.key === 'Enter') {
      searchHandler();
    }
  }

  async function getSearchResult() {
    popupStore.setIsLoading(true);
    clearIcon();

    const response = await (
      await getAPI(`tokens?chain=${chain}&network=${network}&address=${searchAddress}`)
    ).json();

    setIsResult(true);
    setData(response.result);
    if (Object.keys(response.result).length === 0) {
      setDefaultMetadata();
    }
    popupStore.setIsLoading(false);
  }

  function setDefaultMetadata() {
    const defaultFormat = {
      websiteURL: '',
      emailAddress: '',
      whitepaper: '',
      gitHub: '',
      reddit: '',
      twitter: '',
      discord: '',
      telegram: '',
      weChat: '',
      facebook: '',
      slack: '',
      linkedin: '',
      bitcointalk: '',
      medium: '',
      opensea: '',
      ticketing: '',
      priceData: '',
      coinMarketCap: '',
      coinGecko: ''
    };

    setMetadata(JSON.stringify(defaultFormat));
  }

  function clearIcon() {
    // icon 정보 초기화
    setIcon(null);
    setIconPreview(null);
    setIconInfo([]);
    setMimeType('');
    setIconSize(0);
  }

  function setData(s3Data: S3TokenData) {
    if (s3Data.icon && s3Data.mimeType) {
      const decodedFile = dataURLtoFile(s3Data.icon, s3Data.mimeType, 'icon');
      encodeFile(decodedFile);
      setIconInfo({ name: decodedFile.name, size: decodedFile.size, type: decodedFile.type });
      setMimeType(s3Data.mimeType);
      setIconSize(s3Data.iconSize);
      setIcon(decodedFile);
    } else {
      clearIcon();
    }
    setMetadata(JSON.stringify(s3Data.metadata));
  }

  function clearData() {
    setSearchAddress(null);
    setChain('');
    setNetwork('');
    setIconPreview(null);
    setIconInfo({});
    setMimeType('');
    setMetadata('{}');
  }

  function encodeFile(file: File, type?: string) {
    if (!file) {
      return setIconPreview(null);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise<void>((resolve) => {
      reader.onload = () => {
        setIconPreview(reader.result);
        resolve();
      };
    });
  }

  function alertPopup(message: string) {
    popupStore.setConfirm(false);
    popupStore.setMessage(message);
    popupStore.setMethod(() => null);
    popupStore.setToggle(true);
  }

  function confirmPopup(message: string, method?: Function) {
    popupStore.setConfirm(true);
    popupStore.setMessage(message);
    method ? popupStore.setMethod(method) : popupStore.setMethod(() => null);
    popupStore.setToggle(true);
  }

  function iconDelete() {
    let target = document.getElementById('inputFileIcon') as HTMLInputElement;
    target.value = '';
    setIcon(null);
    setIconInfo({});
    setIconPreview(null);
  }

  function iconChangeHandler(e: any) {
    if (e.target.files.length === 0) return iconDelete;
    if (e.target.files === null) return iconDelete;

    let file = e.target.files[0];
    if (!file.type.includes('image')) {
      alertPopup('image 형식의 파일이 아닙니다.');
      return;
    }
    encodeFile(file);
    const fileInfo = {
      name: file.name,
      type: file.type,
      size: file.size
    };

    setIcon(file);
    setIconInfo(fileInfo);
  }

  async function submitTokenInfo() {
    try {
      JSON.parse(metadata);
    } catch (err) {
      alertPopup('JSON format 에 맞지 않습니다.');
      return;
    }

    try {
      let formData = new FormData();
      formData.append('icon', icon || null);
      formData.append('address', searchAddress);
      formData.append('metadata', metadata);
      formData.append('chain', chain);
      formData.append('network', network);

      let response = await (await postFormAPI('tokens/upload', formData)).json();

      if (response.status === '1') {
        alertPopup('처리 완료');
      } else {
        alertPopup(`처리 실패, ${response.result}`);
      }
    } catch (err) {
      console.error((err as Error).message);
      alertPopup(`Internal Error ${(err as Error).message}`);
      return;
    }
  }

  async function deleteTokenInfo() {
    try {
      const bodyParams = {
        address: searchAddress,
        chain: chain,
        network: network
      };

      let response = await (await postAPI('tokens/delete', JSON.stringify(bodyParams))).json();

      if (response.status === '1') {
        alertPopup('삭제 완료');
        navigator(-1);
      } else {
        alertPopup('삭제 실패');
      }
    } catch (err) {
      console.error((err as Error).message);
      alertPopup('Internal Error');
      return;
    }
  }

  return useObserver(() => (
    <>
      {isLoading ? (
        <div className="loadingArea">
          <img className="loadingImg" src={loadingImg} alt="" />
        </div>
      ) : (
        <div className="SearchTokenPage">
          <div className="searchArea">
            <select
              className="chainList"
              defaultValue={chain}
              onChange={(e) => changeChainHandler(e)}
            >
              {chainList.map((c) => {
                return (
                  <option value={c} key={c}>
                    {c}
                  </option>
                );
              })}
            </select>
            <select
              className="networkList"
              defaultValue={network}
              onChange={(e) => changeNetworkHandler(e)}
            >
              {networkList.map((c) => {
                return (
                  <option value={c} key={c}>
                    {c}
                  </option>
                );
              })}
            </select>
            <input
              id="searchInput"
              type="search"
              value={searchAddress}
              onChange={(e) => inputHandler(e.target.value)}
              onKeyPress={(e) => handleKeyPress(e)}
              placeholder="Search"
              autoComplete="off"
            />
            <button className="searchBtn" onClick={() => searchHandler()}>
              Search
            </button>
          </div>

          {isResult && (
            <div className="searchResult">
              <div>
                <h3>Token Info</h3>
                <div className="row">
                  <p className="inputDesc">Address</p>
                  {searchAddress}
                </div>

                <div className="iconUpload">
                  <p className="inputDesc top">Icon</p>
                  <div className="iconPreview">
                    <img alt={iconInfo?.name} src={iconPreview ? iconPreview : noImg} />
                  </div>
                  <input
                    id="inputFileIcon"
                    className="inputFile short"
                    accept="image/*"
                    type="file"
                    onChange={(e) => iconChangeHandler(e)}
                    ref={fileInput}
                  />
                  <label htmlFor="inputFileIcon">
                    <AttachFileIcon />
                    {iconInfo?.name || '등록된 파일이 없습니다.'}
                  </label>
                  <div className="listBtn btnDelete" onClick={() => iconDelete()}>
                    Delete
                  </div>
                </div>

                <div className="row">
                  <p className="inputDesc">Icon MimeType</p>
                  {mimeType}
                </div>

                <div className="row">
                  <p className="inputDesc">Icon Size (Bytes)</p>
                  {iconSize}
                </div>

                <div className="row">
                  <p className="inputDesc">Metadata</p>
                  <textarea
                    name=""
                    id=""
                    rows={20}
                    cols={100}
                    value={metadata}
                    onChange={(e) => textAreaHandler(e.target.value)}
                  ></textarea>
                </div>
                <div className="row">
                  <p className="inputDesc">JSON Prettier</p>
                  <button className="formatBtn" onClick={() => jsonFormatHandler()}>
                    JSON prettier
                  </button>
                </div>
              </div>

              <div className="buttonArea">
                <div
                  className="listBtn btnSubmit"
                  onClick={() => confirmPopup('제출 하시겠습니까?', submitTokenInfo)}
                >
                  Update
                </div>

                <div
                  className="listBtn btnClose"
                  onClick={() => confirmPopup('삭제 하시겠습니까?', deleteTokenInfo)}
                >
                  Delete
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  ));
}
